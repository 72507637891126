<template>
  <t-split-screen centered>
    <div>
      <nuxt-link class="logout" :to="{ name: $RouteEnum.LOGOUT }">
        {{ $t('global.actions.logout') }}
      </nuxt-link>
      <h1>{{ $t('pages.email_verification.heading') }}</h1>

      <i18n path="pages.email_verification.code_sent" tag="p" class="text--grey">
        <strong>{{ $auth.user.email }}</strong>
      </i18n>

      <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
        <t-form @submit.native.prevent="handleSubmit(verify)">
          <t-validation-wrapper
            vid="code"
            :name="$t('fields.verification_code')"
            rules="required|numeric|length:6"
          >
            <t-code-input v-model="segments" />
          </t-validation-wrapper>

          <t-form-element>
            <t-button-loading :loading="loading" :disabled="invalid" native-type="submit">
              {{ $t('global.actions.verify') }}
            </t-button-loading>
          </t-form-element>
        </t-form>
      </validation-observer>

      <nuxt-link :to="{ name: $RouteEnum.LOGOUT, query: { redirectAfterLogout } }">
        {{ $t('pages.email_verification.wrong_email') }}
      </nuxt-link>
      <br><br>

      <span class="text--grey">
        {{ $t('pages.email_verification.check_spam') }}<br>
        <a href="#" @click.prevent="resendEmail">{{
          $t('pages.email_verification.actions.resend')
        }}</a>
      </span>
    </div>
    <dashboard-image slot="secondary" name="verification" />
  </t-split-screen>
</template>

<script>
import TValidationWrapper from '~/components/TValidationWrapper';
import DashboardImage from '~/components/DashboardImage.vue';

export default {
  name: 'verify-channel-view',

  components: {
    DashboardImage,
    TValidationWrapper,
  },

  layout: 'standalone',

  middleware: [
    'auth',
    ({ $auth, redirect, $RouteEnum }) => {
      if ($auth.isVerified) {
        redirect({ name: $RouteEnum.DASHBOARD });
      }
    },
  ],

  data() {
    return {
      loading: false,
      segments: [],
    };
  },

  computed: {
    code() {
      return this.segments.join('');
    },

    redirectAfterLogout() {
      if (this.$auth.isEmployee) {
        return this.$RouteEnum.REGISTER_EMPLOYEE;
      }
      if (this.$auth.isApplicant) {
        return this.$RouteEnum.REGISTER_APPLICANT;
      }
      return null;
    },
  },

  methods: {
    async verify() {
      this.loading = true;
      try {
        await this.$axios.post('/auth/email/verify', {
          code: this.code,
        });

        this.$auth.commit('PATCH_USER', {
          verified: true,
        });

        await this.$router.pushExpectRedirect({ name: this.$RouteEnum.DASHBOARD });
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    async resendEmail() {
      try {
        await this.$axios.post('/auth/email/resend');

        await this.$notify.success(this.$t('notifications.auth.verify.new_email_sent'));
        await this.$router.pushExpectRedirect({ name: this.$RouteEnum.DASHBOARD });
      } catch (e) {
        this.$axios.handleError(e);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.t-split-screen::v-deep .t-split-screen__primary {
  position: relative;
}

.logout {
  position: absolute;
  top: $s-4;
  right: $s-4;
}
</style>
